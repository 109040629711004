import React from 'react';
import { connect } from 'react-redux';
import '../../../styles/screens/nyumba.scss';
import { say, alert, preloaderShow, preloaderHide } from "../../../actions";
import { config } from "../../../app/config";
import TranslationService from "../../../app/services/TranslationService";

const icons = {
    0: 'imd-icon imd-icon-nyumba',
    1: 'fa fa-mobile',
    2: 'fa fa-globe',
    3: 'fa fa-user-circle-o',
    4: 'fa fa-headphones',
    5: 'fa fa-television',
    6: 'fa fa-google',
    7: 'fa fa-sun-o',
    8: 'imd-icon imd-icon-tv',
    9: 'fa fa-globe',
    10: 'svg xiaomi_mi_home',
    11: 'fa fa-microchip',
    12: 'fa fa-plug',
    13: 'fa fa-puzzle-piece',
    14: 'fa fa-podcast',
    15: 'fa fa-tint',
    16: 'fa fa-fire',
    17: 'fa fa-fire',
    18: 'fa fa-thermometer-quarter',
    19: 'fa fa-fire-extinguisher',
    20: 'fa fa-connectdevelop',
    21: 'fa fa-microchip',
    22: 'xiaomi|{"margin": "0 3px 0 -3px"}',
    23: 'fa fa-lightbulb-o',
    24: 'fa fa-puzzle-piece',
    25: 'imd-icon imd-icon-brightness-contrast',
    26: 'fa fa-podcast',
    27: 'nyumba|{"margin": "0 5px 0 -5px"}',
    28: 'fa-solid fa-house-signal',
    29: 'imd-icon imd-icon-fire',
    30: 'fa-regular fa-square-minus,fa-regular fa-square|{"transform": "rotate(90deg)", "margin": "10px 10px -10px -10px"}',
    1300: 'svg icymat'
}

const allowedActions = {
    '4': {
        'play_radio': 'imd-icon imd-icon-play2',
        'play_stop': 'imd-icon imd-icon-stop'
    },
    '12': {
        'on': 'imd-text-green fa-solid fa-circle',
        'off': 'imd-text-red fa-regular fa-circle'
    },
    '22': {
        'scale': 10
    },
    '25': {
        'rgb color 255 255 255 128': 'imd-text-white fa-solid fa-circle',
        'off': 'fa-regular fa-circle',
    },
    '27': {
        'play_radio': 'imd-icon imd-icon-play2',
        'play_stop': 'imd-icon imd-icon-stop'
    },
    '29': {
        'off': 'imd-icon imd-icon-radio-unchecked'
    },
    '30': {
        'switch_on': 'imd-text-green fa-solid fa-circle',
        'switch_off': 'imd-text-red fa-regular fa-circle'
    }
};

export class NyumbaDevicesList extends React.Component
{
    prepareDevicesObject = () => {
        let rooms = {};
        let objectByRooms = {};

        Object.keys(this.props.devices).forEach(deviceId => {
            let device = this.props.devices[deviceId];

            let roomName = device.room === null ? TranslationService.trans(this.props.language, 'nyumba.devices.unknown_devices') : device.room.name;
            let roomOrder = device.room === null ? 100000 : device.room.order;

            if (rooms[roomName] === undefined) {
                rooms[roomName] = [roomName, roomOrder];
            }
        });

        rooms = Object.values(rooms);
        rooms.sort(function(a, b) {
            return a[1] - b[1];
        });
        rooms.forEach(room => {
            objectByRooms[room[0]] = [];
        })

        Object.keys(this.props.devices).forEach(deviceId => {
            let device = this.props.devices[deviceId];

            let roomName = device.room === null ? TranslationService.trans(this.props.language, 'nyumba.devices.unknown_devices') : device.room.name;

            objectByRooms[roomName].push(device);
        });

        return objectByRooms;
    }

    renderDevices = (devices) => {
        const currentDate = new Date();
        const timestamp = Math.round(currentDate.getTime() / 1000);

        return (
            <div className={"imd-row"}>
                {
                    Object.keys(devices).map(id => {
                        let device = devices[id];
                        let iconClass = icons[device.type.id] === undefined ? icons[1] : icons[device.type.id];

                        let temp = iconClass.split('|');
                        iconClass = temp[0];
                        iconClass = iconClass.split(',');
                        let iconIndex = 0;

                        if (device.hasOwnProperty('icon') >= 0 && device.icon !== null) {
                            if (device.icon.hasOwnProperty('alternative')) {
                                iconIndex = device.icon.alternative;
                            }
                        }

                        iconClass = iconClass[iconIndex];

                        let iconFilters = {};
                        if (temp.length > 1) {
                            iconFilters = JSON.parse(temp[1]);
                        }

                        if (device.type.id <= 3 || device.type.name === 'TYPE_UNKNOWN_DEVICE' || (config.env !== 'dev' && Math.abs(timestamp - device.last_report) > 15 * 60)) {
                            return (<span />);
                        }

                        return (<div key={id} className={'imd-col-xs-12 imd-col-md-6 imd-col-lg-3'}>
                            <div className={'imd-shadow-dark imd-text-white device'} style={{margin: '7px', padding: '13px'}}>
                                <div className={"icon-container"}>
                                    {this.renderDeviceIcon(device, iconClass, iconFilters)}
                                </div>
                                <div className={"properties-container"}>
                                    <span className={'name'}>{device.custom_name !== null && device.custom_name !== '' ? device.custom_name : device.name}</span>

                                    {this.renderDeviceActions(device)}
                                </div>
                            </div>
                        </div>)
                    })
                }
            </div>
        );
    }

    renderDeviceIcon = (device, iconClass, iconFilters) => {
        if (iconClass === 'nyumba') {
            iconFilters.height = '50px';
            return (<img src={'images/nyumba/icons/nyumba.png'} alt="Nyumba" style={iconFilters} />);
        } else if (iconClass === 'xiaomi') {
            iconFilters.height = '50px';
            return (<img src={'images/nyumba/icons/xiaomi.png'} alt="Nyumba" style={iconFilters} />);
        }

        return (<span className={"icon imd-text-150-silver " + iconClass} style={iconFilters} />);
    }

    renderDeviceActions = (device) => {
        if (!allowedActions.hasOwnProperty(device.type.id)) {
            return (<div className={'actions'}></div>);
        }

        const actions = allowedActions[device.type.id];
        let that = this;

        return (
            <div className={'actions'}>
                {
                    Object.keys(actions).map(action => {
                        if (action === 'scale') {
                            let scale = [0];
                            for (let x = 1; x <= actions[action]; x++) {
                                scale.push(x);
                            }

                            return (<span>
                                {
                                    Object.keys(scale).map(x => {
                                        return (
                                            <span
                                                style={{marginLeft: '3px', cursor: 'pointer'}}
                                                className={x > 0 ? 'imd-text-green' : 'imd-text-red'}
                                                onClick={() => that.props.sendAction('DEVICE: ' + device.id + ' set ' + x)}>
                                                {x}
                                            </span>
                                        )
                                    })
                                }
                            </span>)
                        }

                        const icon = actions[action];

                        return (<button
                            className={'imd-text-accent'}
                            style={{fontSize: '1.5rem', border: 'none', background: 'none', padding: 0, cursor: 'pointer'}}
                            onClick={() => that.props.sendAction('DEVICE: ' + device.id + ' ' + action)}
                        >
                            <span className={icon}></span>
                        </button>)
                    })
                }
            </div>
        );
    }

    render = () => {
        const devices = this.prepareDevicesObject();

        return (
            <div>
                {
                    Object.keys(devices).map(name => {
                        return (
                            <div className={"imd-container-fluid"}>
                                <div className={"imd-row"}>
                                    <div className={"imd-col-xs-12"}>
                                        <h2 className={"imd-text-accent"}>{name}</h2>
                                    </div>
                                </div>

                                {this.renderDevices(devices[name])}
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        language: TranslationService.getLanguage(state.user),
        view: state.view
    }
};

const mapDispatchToProps = {
    say,
    alert,
    preloaderShow,
    preloaderHide
};

export const DevicesList = connect(mapStateToProps, mapDispatchToProps)(NyumbaDevicesList);
